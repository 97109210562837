<template>
  <div>
    <a-modal
      :visible="visible"
      title="编辑工艺路线"
      :width="1400"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="6">
            <a-form-item label="编号">
              <a-input
                v-decorator="[
                  'number',
                  {
                    rules: [
                      { required: true, message: '请输入编号' },
                      { max: 32, message: '超出最大长度(32)' },
                    ],
                    initialValue: item.number,
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: '请输入名称' },
                      { max: 64, message: '超出最大长度(64)' },
                    ],
                    initialValue: item.name,
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="激活状态">
              <a-select
                v-decorator="[
                  'is_active',
                  {
                    initialValue: item.is_active,
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option :value="true">激活</a-select-option>
                <a-select-option :value="false">冻结</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="备注">
              <a-input
                v-decorator="[
                  'remark',
                  {
                    rules: [{ max: 256, message: '超出最大长度(256)' }],
                    initialValue: item.remark,
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="组成工序" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
              <ProductionProcessTable
                v-decorator="['production_process_items', { initialValue: item.production_process_items }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="关联物料" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
              <RelateProductTable v-decorator="['relate_goods_items', { initialValue: item.goods_items }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { productionRouteUpdate } from "@/api/production";

export default {
  components: {
    ProductionProcessTable: () => import("./ProductionProcessTable"),
    RelateProductTable: () => import("./RelateProductTable"),
  },
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: this.$form.createForm(this),
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          // 验证组成工序
          if (values.production_process_items.length === 0) {
            this.$message.warning("组成工序未添加工序");
            return;
          }

          for (const productionProcessItem of values.production_process_items) {
            if (!productionProcessItem.process) {
              this.$message.warning("组成工序未选择工序");
              return;
            }
          }

          // 验证关联物料
          if (values.relate_goods_items.length === 0) {
            this.$message.warning("关联物料未添加物料");
            return;
          }

          for (const relateGoodsItem of values.relate_goods_items) {
            if (!relateGoodsItem.id) {
              this.$message.warning("关联物料未选择物料");
              return;
            }
          }
          values["goods_set"] = values.relate_goods_items.map((item) => item.id);

          this.confirmLoading = true;
          productionRouteUpdate({ id: this.item.id, ...values })
            .then((data) => {
              this.$emit("update", data);
              this.$message.success("更新成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style scoped></style>
